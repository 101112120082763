import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/istaga_blog_header.png");
const section_1 = require("../../../assets/img/blogs/istaga_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/istaga_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/istaga_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/istaga_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/istaga_blog_img_5.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Meeting Internal SLA Targets with Generative AI Service Desks"
        description="A Generative AI service desk is instrumental to empowering organizations in meeting internal SLA targets by facilitating service desk operations and ensuring user experience."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt istaga_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-normal">
                    Meeting Internal SLA Targets with Generative AI Service
                    Desks:{" "}
                    <span className="font-page-header-home-blog">
                      Enhancing Support Performance
                    </span>
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-normal">
                    Meeting Internal SLA
                    <br /> Targets with Generative AI
                    <br /> Service Desks:{" "}
                    <span className="font-page-header-home-blog">
                      Enhancing
                      <br /> Support Performance
                    </span>
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is an SLA or Service Level Agreement?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What is an internal SLA or Service Level Agreement?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Why do you need an internal SLA for employee IT support?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Can a service desk define an SLA's success?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How can Generative AI help meet an SLA target?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. What are the areas where the Gen AI service desk
                  facilitates internal support?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Internal service level agreement with Workativ
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Whether you are a product-based enterprise or solution provider,
                an ITSM is a familiar component for companies like yours to
                empower internal users and provide uninterrupted{" "}
                <a href="https://workativ.com/conversational-ai-platform/it-support-chatbot">
                  IT support
                </a>{" "}
                and delivery for your business's success. The existing
                challenges for ITSM are also not hidden from anyone.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                  {" "}
                  Generative Artificial Intelligence
                </a>{" "}
                has set high expectations for enterprise leaders to make ITSM
                flexible and agile.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are many different sides to ITSM, where GenAI has brought
                a potential change.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-itops">
                  IT support operations, AIOps,
                </a>{" "}
                DevOps, help desk, and a variety of ITSM components see a major
                overhaul for Generative AI.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Considering the powerful capabilities of Generative AI, we can
                say every little detail of ITSM can be improved and reimagined.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                That said, the internal Service Level Agreement or SLA for ITSM
                can also be maintained with such a flair that enterprise leaders
                can set high expectations for it and attain the business
                objectives through proper compliance with ITSM SLA using
                Generative AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But why does internal SLA matter so much to ITSM leaders, and
                how does a Generative AI-powered service desk helps gain
                internal SLA targets for their internal employees? Let’s learn.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is an SLA or Service Level Agreement?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Though what SLA refers to is different from what internal SLA
                means, the objective of both terms are equal, facilitating
                service delivery, user performance, and ensuring user
                experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                From the keyword{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-trends-2024">
                  ‘Service Level Agreement,’
                </a>{" "}
                let’s make a simple sense of the entire thing 一 which is to
                follow an agreement for what is stated or promised for service
                delivery to the user.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In a particular scenario of a service level agreement, it
                involves a vendor and a buyer to whom a certain or some level of
                service agreement is made, in which it is stated what, how, and
                when a service will be delivered as per expectations.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For a product-based company, it is obvious to have some stack of
                applications. Say, your organization uses a screen-recording
                application from a SaaS-based company. As the SLA goes, the
                company promises to deliver 240 hours of free recording within
                30 days. It can add more clauses to the SLA. If those free hours
                aren’t utilized within 30 days, they will be removed normally.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But as stated in the SLA, if users cannot access those free
                hours of recording, they are deprived of what is promised to get
                their work done.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                So, an SLA makes it mandatory to adhere to clauses and
                parameters that represent violations of those documented
                clauses.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is an internal SLA or Service Level Agreement?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                An internal SLA means offering service level agreement to the
                internal employees from the employer’s end.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is easy to understand that an internal SLA is not between the
                customer and a vendor. Rather, an employer adheres to some
                policies or a piece of the agreement for its employees so as to
                deliver the right kind of IT support to them and help them gain
                operational efficiency in their day-to-day job.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An internal SLA also involves{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/employee-it-service-desk-metrics">
                  performance metrics
                </a>{" "}
                and service expectations between two departments of an
                organization.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Whatever it may concern, an internal SLA ensures performance
                delivery for the promised services utilizing optimized response
                and resolution times.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To further reflect upon an internal SLA, it can refer to
                providing network or application access, password management,
                account unlock assistance, etc for employees within the right
                time through proper IT support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if an internal SLA mentions a system downtime must
                be addressed within a day, it must occur as stated in SLA. So, a
                ticket must be created, addressed, and closed within 24 hours.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Customer SLA vs. Internal SLA - a quick comparison
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="SLA comparison for service "
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do you need an internal SLA for employee IT support?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                The key objective of an SLA is to facilitate incident management
                and improve performance for organizational services, which later
                translates to user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If an organization does not meet its SLA target, IT support may
                be compromised, and organizational resilience may be impacted.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, an incident is communicated through a service desk
                for response and resolution just in time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An SLA defines how a particular incident must be addressed
                within a specified time frame.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now imagine an SLA is not documented for downtime in an
                application. Employees would have longer days of downtime and
                productivity issue. At the same time, the service desk is not
                supposed to be careful about a timely resolution of the
                incident, which impacts operational efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, if an internal SLA is there, it is the service desk’s
                responsibility to resolve the issue as specified in SLA. That’s
                why an SLA is integral to a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                  service desk.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Can a service desk define an SLA's success?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The fact about the SLA compliance success rate is it depends on
                what number of tickets are resolved.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In a natural way, a service desk is always involved in resolving
                a ticket.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                To calculate an SLA compliance success rate, it goes like this:
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="SLA compliance ratio for service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Documenting service-level agreement can articulate steps or
                commands for users to implement successful performance delivery
                and meeting end-user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, SLA compliance is less effective unless there is a proper
                tool to facilitate employee IT support.{" "}
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An organization's service desk is everything to meet SLA targets
                by resolving IT incident tickets and maintaining employee
                support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk can facilitate incident resolution, appropriate
                service delivery, and employee performance management by
                providing ample tools and features.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that said, a more powerful service desk means faster and
                speedier compliance with SLA through more ticket resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A Generative AI service desk may be your answer to meet SLA
                targets.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How can Generative AI help meet an SLA target?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Generative Artificial Intelligence's key potential lies in the
                generation of new content, summarization, classification, and
                categorization.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                The embedded natural language processing capability in
                Generative AI helps the service desk parse intricate data and
                provide better suggestions for incident handling, which
                alternatively helps speed up SLA compliance.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automating incident communication
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                It is an obvious service desk activity to register an incident
                report whenever there occurs one. But from composing a service
                incident description to having the right person to look at the
                message, the communication path does not seem easy.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                If the message is factually or contextually correct, a service
                desk can recommend steps for mitigation plans.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                However, providing the service desk with the correct and
                contextual incident description is not always easy.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                For instance, a person responsible for sending a message may not
                have the knowledge to craft a message. As a result, there might
                be a delay in receiving a message.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk">
                  large language model-powered service desk
                </a>{" "}
                simplifies the composition of a new service incident description
                by automating content generation. Using just a few prompts, an
                incident management requester can create a new message and send
                it to the service desk.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Incident response message creation using Generative AI                "
              />
              <p class="font-section-normal-text-testimonials line-height-18 ">
                The content generation capability also helps create a
                standardized format for everyone at the service desk, which also
                improves contextual gains and speedier response generation.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Simplifying incident responses
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                When an incident occurs, a service desk receives several types
                of incident messages for the same incident. A different layer of
                incident message points may come from product engineers, a
                monitoring system, or internal employees. The challenge is that
                the message includes various formats, such as textual
                statements, images, a string of scripts, etc., making it
                difficult for the service desk manager to comprehend the
                message.
              </p>
              <BlogCta
                ContentCta="Improve your Internal SLA with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                The ability to parse human language using NLP, a large language
                model, or a Generative AI service desk can help remove
                information overload from the service description using the
                “extract” prompt.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                All it needs for the service desk manager is to copy the
                ‘incident description,’ paste it into the LLM interface and
                prompt it to extract key incident information. An LLM-powered
                service desk easily extracts key information and produces
                consolidated incident responses for the service desk agent or
                manager.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Utilization of prompt ‘Extract’ to extract key incident responses"
              />
              <p class="font-section-normal-text-testimonials line-height-18 ">
                The advantage is the service desk does not need to spend extra
                time and effort to comprehend an incident message to create a
                ticket and communicate with the right person to address an
                incident.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Unified incident diagnosis and mitigation
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                A fine-tuned large language model trained with historical data
                of a specific domain can be instrumental to empowering service
                desk operations and helping agents to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk">
                  automate incident diagnosis and mitigation plans.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                Due to this flexibility, a Generatie AI service desk can use
                incident summary, title, and description to learn the pattern
                across its database and produce an appropriate analysis of the
                incident and its root cause based on retrieval augmentation
                techniques.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                RAG, or Retrieval Augmentation Generation, helps a large
                language model to retrieve historical data from the database and
                extract contexts from incident documents or logged reports to
                generate coherent responses to the service desk inquiries.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" Incident diagnosis and mitigation using the generative ai-powered interface within the service desk"
              />
              <p class="font-section-normal-text-testimonials line-height-18 ">
                By facilitating a chat interface to allow incident discussion, a
                GenAI-powered service desk can offer unified discussion on
                incident diagnosis and help accelerate decision-making on
                mitigation plans and reduce the length of downtime.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                It is observed now that a Generative AI-powered service desk
                offers ample tools and features to handle incidents and resolve
                tickets with a maximum level of precision and speed. It would
                dramatically translate to a powerful service desk that helps
                organizations fully comply with an internal service-level
                agreement. As a result, a company is empowered to ensure
                enhanced performance delivery and offer elevated user
                experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the areas where the Gen AI service desk facilitates
                internal support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                On a day-to-day basis, an organization can use a Generative AI
                service desk to facilitate employee support while maintaining
                internal SLA without a breach of the document.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Fixing a bug -
                  </span>{" "}
                  Say a team of developers uses a code editor tool. Suddenly, a
                  bug incident in this tool is reported, and the team can
                  experience a productivity issue. An LLM-powered service desk
                  can help handle the rapidly and reinstate operational
                  efficiency with minimal impact on the operations.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Assigning a new tool -
                  </span>{" "}
                  By{" "}
                  <a href="https://workativ.com/use-cases/user-provisioning-automation">
                    automating a workflow for asset provisioning,
                  </a>{" "}
                  a Generative AI service desk can remove manual efforts yet
                  help facilitate the task without any disruption to the user
                  experience.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Helping with password resets -
                  </span>{" "}
                  A Generative AI service desk can offer{" "}
                  <a href="https://workativ.com/use-cases/reset-password-automation">
                    hyper-automation response for self-service capability with
                    password resets.&nbsp;
                  </a>
                  With Generative AI, security is also prioritized with two-way
                  authentication factors before implementing the password resets
                  with a link. Besides, a service desk can lessen its time to
                  improve employee support by sending password reset links for
                  an application that is about to expire.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Employee onboarding -
                  </span>{" "}
                  A new employee experience can be elevated on the very first
                  day by{" "}
                  <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                    automating employee onboarding
                  </a>{" "}
                  with the use of a service desk powered by Generative AI. From
                  software provisioning to identity approvals, everything can be
                  done to provide a better experience and optimize a new hire's
                  time without making him/her feel frustrated.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                When using a Generative AI service desk, a company can unleash
                unique use cases to improve employee support performance while
                maintaining an internal SLA.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Internal service level agreement with Workativ
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                uses Generative AI properties in its self-service chatbot to
                elevate the service desk experience. We combine all the right
                tools and features to allow organizations to improve support
                performance, deliver operational efficiency for employees, and
                maintain user experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Our Generative AI-powered service desk complies with the
                industry standard to deliver employee support and helps you
                maintain internal service level agreements.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                We provide an{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                  analytics dashboard
                </a>{" "}
                to examine chatbot logs ─ what number of tickets are handled
                successfully and how many remain unattended to analyze the
                health of an internal SLA. Based on the analytics, it is easier
                to detect room for improvement and implement the right
                improvement strategy.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In a nutshell, Workativ helps you leverage the LLM-powered
                chatbot for service desk operations and helps you meet internal
                SLA targets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="/conversational-ai-platform/demo">
                  Connect with Workativ today for a personalized demo.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is an SLA or Service Level Agreement?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What is an internal SLA or Service Level Agreement?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Why do you need an internal SLA for employee IT support?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Can a service desk define an SLA's success?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How can Generative AI help meet an SLA target?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. What are the areas where the Gen AI service desk
                    facilitates internal support?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Internal service level agreement with Workativ
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Whether you are a product-based enterprise or solution provider,
                an ITSM is a familiar component for companies like yours to
                empower internal users and provide uninterrupted{" "}
                <a href="https://workativ.com/conversational-ai-platform/it-support-chatbot">
                  IT support
                </a>{" "}
                and delivery for your business's success. The existing
                challenges for ITSM are also not hidden from anyone.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                  {" "}
                  Generative Artificial Intelligence
                </a>{" "}
                has set high expectations for enterprise leaders to make ITSM
                flexible and agile.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are many different sides to ITSM, where GenAI has brought
                a potential change.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-itops">
                  IT support operations, AIOps,
                </a>{" "}
                DevOps, help desk, and a variety of ITSM components see a major
                overhaul for Generative AI.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Considering the powerful capabilities of Generative AI, we can
                say every little detail of ITSM can be improved and reimagined.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                That said, the internal Service Level Agreement or SLA for ITSM
                can also be maintained with such a flair that enterprise leaders
                can set high expectations for it and attain the business
                objectives through proper compliance with ITSM SLA using
                Generative AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But why does internal SLA matter so much to ITSM leaders, and
                how does a Generative AI-powered service desk helps gain
                internal SLA targets for their internal employees? Let’s learn.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is an SLA or Service Level Agreement?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Though what SLA refers to is different from what internal SLA
                means, the objective of both terms are equal, facilitating
                service delivery, user performance, and ensuring user
                experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                From the keyword{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-trends-2024">
                  ‘Service Level Agreement,’
                </a>{" "}
                let’s make a simple sense of the entire thing 一 which is to
                follow an agreement for what is stated or promised for service
                delivery to the user.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In a particular scenario of a service level agreement, it
                involves a vendor and a buyer to whom a certain or some level of
                service agreement is made, in which it is stated what, how, and
                when a service will be delivered as per expectations.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For a product-based company, it is obvious to have some stack of
                applications. Say, your organization uses a screen-recording
                application from a SaaS-based company. As the SLA goes, the
                company promises to deliver 240 hours of free recording within
                30 days. It can add more clauses to the SLA. If those free hours
                aren’t utilized within 30 days, they will be removed normally.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But as stated in the SLA, if users cannot access those free
                hours of recording, they are deprived of what is promised to get
                their work done.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                So, an SLA makes it mandatory to adhere to clauses and
                parameters that represent violations of those documented
                clauses.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is an internal SLA or Service Level Agreement?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                An internal SLA means offering service level agreement to the
                internal employees from the employer’s end.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is easy to understand that an internal SLA is not between the
                customer and a vendor. Rather, an employer adheres to some
                policies or a piece of the agreement for its employees so as to
                deliver the right kind of IT support to them and help them gain
                operational efficiency in their day-to-day job.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An internal SLA also involves{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/employee-it-service-desk-metrics">
                  performance metrics
                </a>{" "}
                and service expectations between two departments of an
                organization.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Whatever it may concern, an internal SLA ensures performance
                delivery for the promised services utilizing optimized response
                and resolution times.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To further reflect upon an internal SLA, it can refer to
                providing network or application access, password management,
                account unlock assistance, etc for employees within the right
                time through proper IT support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if an internal SLA mentions a system downtime must
                be addressed within a day, it must occur as stated in SLA. So, a
                ticket must be created, addressed, and closed within 24 hours.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Customer SLA vs. Internal SLA - a quick comparison
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="SLA comparison for service "
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do you need an internal SLA for employee IT support?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                The key objective of an SLA is to facilitate incident management
                and improve performance for organizational services, which later
                translates to user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If an organization does not meet its SLA target, IT support may
                be compromised, and organizational resilience may be impacted.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, an incident is communicated through a service desk
                for response and resolution just in time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An SLA defines how a particular incident must be addressed
                within a specified time frame.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now imagine an SLA is not documented for downtime in an
                application. Employees would have longer days of downtime and
                productivity issue. At the same time, the service desk is not
                supposed to be careful about a timely resolution of the
                incident, which impacts operational efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, if an internal SLA is there, it is the service desk’s
                responsibility to resolve the issue as specified in SLA. That’s
                why an SLA is integral to a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                  service desk.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Can a service desk define an SLA's success?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The fact about the SLA compliance success rate is it depends on
                what number of tickets are resolved.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In a natural way, a service desk is always involved in resolving
                a ticket.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                To calculate an SLA compliance success rate, it goes like this:
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="SLA compliance ratio for service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Documenting service-level agreement can articulate steps or
                commands for users to implement successful performance delivery
                and meeting end-user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, SLA compliance is less effective unless there is a proper
                tool to facilitate employee IT support.{" "}
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An organization's service desk is everything to meet SLA targets
                by resolving IT incident tickets and maintaining employee
                support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk can facilitate incident resolution, appropriate
                service delivery, and employee performance management by
                providing ample tools and features.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that said, a more powerful service desk means faster and
                speedier compliance with SLA through more ticket resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A Generative AI service desk may be your answer to meet SLA
                targets.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How can Generative AI help meet an SLA target?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Generative Artificial Intelligence's key potential lies in the
                generation of new content, summarization, classification, and
                categorization.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                The embedded natural language processing capability in
                Generative AI helps the service desk parse intricate data and
                provide better suggestions for incident handling, which
                alternatively helps speed up SLA compliance.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automating incident communication
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                It is an obvious service desk activity to register an incident
                report whenever there occurs one. But from composing a service
                incident description to having the right person to look at the
                message, the communication path does not seem easy.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                If the message is factually or contextually correct, a service
                desk can recommend steps for mitigation plans.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                However, providing the service desk with the correct and
                contextual incident description is not always easy.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                For instance, a person responsible for sending a message may not
                have the knowledge to craft a message. As a result, there might
                be a delay in receiving a message.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk">
                  large language model-powered service desk
                </a>{" "}
                simplifies the composition of a new service incident description
                by automating content generation. Using just a few prompts, an
                incident management requester can create a new message and send
                it to the service desk.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Incident response message creation using Generative AI                "
              />
              <p class="font-section-normal-text-testimonials line-height-18 ">
                The content generation capability also helps create a
                standardized format for everyone at the service desk, which also
                improves contextual gains and speedier response generation.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Simplifying incident responses
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                When an incident occurs, a service desk receives several types
                of incident messages for the same incident. A different layer of
                incident message points may come from product engineers, a
                monitoring system, or internal employees. The challenge is that
                the message includes various formats, such as textual
                statements, images, a string of scripts, etc., making it
                difficult for the service desk manager to comprehend the
                message.
              </p>
              <BlogCta
                ContentCta="Improve your Internal SLA with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                The ability to parse human language using NLP, a large language
                model, or a Generative AI service desk can help remove
                information overload from the service description using the
                “extract” prompt.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                All it needs for the service desk manager is to copy the
                ‘incident description,’ paste it into the LLM interface and
                prompt it to extract key incident information. An LLM-powered
                service desk easily extracts key information and produces
                consolidated incident responses for the service desk agent or
                manager.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Utilization of prompt ‘Extract’ to extract key incident responses"
              />
              <p class="font-section-normal-text-testimonials line-height-18 ">
                The advantage is the service desk does not need to spend extra
                time and effort to comprehend an incident message to create a
                ticket and communicate with the right person to address an
                incident.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Unified incident diagnosis and mitigation
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                A fine-tuned large language model trained with historical data
                of a specific domain can be instrumental to empowering service
                desk operations and helping agents to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk">
                  automate incident diagnosis and mitigation plans.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                Due to this flexibility, a Generatie AI service desk can use
                incident summary, title, and description to learn the pattern
                across its database and produce an appropriate analysis of the
                incident and its root cause based on retrieval augmentation
                techniques.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                RAG, or Retrieval Augmentation Generation, helps a large
                language model to retrieve historical data from the database and
                extract contexts from incident documents or logged reports to
                generate coherent responses to the service desk inquiries.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" Incident diagnosis and mitigation using the generative ai-powered interface within the service desk"
              />
              <p class="font-section-normal-text-testimonials line-height-18 ">
                By facilitating a chat interface to allow incident discussion, a
                GenAI-powered service desk can offer unified discussion on
                incident diagnosis and help accelerate decision-making on
                mitigation plans and reduce the length of downtime.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                It is observed now that a Generative AI-powered service desk
                offers ample tools and features to handle incidents and resolve
                tickets with a maximum level of precision and speed. It would
                dramatically translate to a powerful service desk that helps
                organizations fully comply with an internal service-level
                agreement. As a result, a company is empowered to ensure
                enhanced performance delivery and offer elevated user
                experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the areas where the Gen AI service desk facilitates
                internal support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                On a day-to-day basis, an organization can use a Generative AI
                service desk to facilitate employee support while maintaining
                internal SLA without a breach of the document.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Fixing a bug -
                  </span>{" "}
                  Say a team of developers uses a code editor tool. Suddenly, a
                  bug incident in this tool is reported, and the team can
                  experience a productivity issue. An LLM-powered service desk
                  can help handle the rapidly and reinstate operational
                  efficiency with minimal impact on the operations.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Assigning a new tool -
                  </span>{" "}
                  By{" "}
                  <a href="https://workativ.com/use-cases/user-provisioning-automation">
                    automating a workflow for asset provisioning,
                  </a>{" "}
                  a Generative AI service desk can remove manual efforts yet
                  help facilitate the task without any disruption to the user
                  experience.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Helping with password resets -
                  </span>{" "}
                  A Generative AI service desk can offer{" "}
                  <a href="https://workativ.com/use-cases/reset-password-automation">
                    hyper-automation response for self-service capability with
                    password resets.&nbsp;
                  </a>
                  With Generative AI, security is also prioritized with two-way
                  authentication factors before implementing the password resets
                  with a link. Besides, a service desk can lessen its time to
                  improve employee support by sending password reset links for
                  an application that is about to expire.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Employee onboarding -
                  </span>{" "}
                  A new employee experience can be elevated on the very first
                  day by{" "}
                  <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                    automating employee onboarding
                  </a>{" "}
                  with the use of a service desk powered by Generative AI. From
                  software provisioning to identity approvals, everything can be
                  done to provide a better experience and optimize a new hire's
                  time without making him/her feel frustrated.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                When using a Generative AI service desk, a company can unleash
                unique use cases to improve employee support performance while
                maintaining an internal SLA.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Internal service level agreement with Workativ
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                uses Generative AI properties in its self-service chatbot to
                elevate the service desk experience. We combine all the right
                tools and features to allow organizations to improve support
                performance, deliver operational efficiency for employees, and
                maintain user experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Our Generative AI-powered service desk complies with the
                industry standard to deliver employee support and helps you
                maintain internal service level agreements.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                We provide an{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                  analytics dashboard
                </a>{" "}
                to examine chatbot logs ─ what number of tickets are handled
                successfully and how many remain unattended to analyze the
                health of an internal SLA. Based on the analytics, it is easier
                to detect room for improvement and implement the right
                improvement strategy.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In a nutshell, Workativ helps you leverage the LLM-powered
                chatbot for service desk operations and helps you meet internal
                SLA targets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="/conversational-ai-platform/demo">
                  Connect with Workativ today for a personalized demo.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-service-management-guide"
              className="font-section-normal-text-testimonials"
            >
              A Complete Guide - 2023: HR Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023"
              className="font-section-normal-text-testimonials"
            >
              The ultimate guide - 2023: HR DIGITAL TRANSFORMATION
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-digital-transformation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Why do you need ITSM in your Digital Trasformation?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
